
// number of times per year
const frequencies = {
    "Weekly": 52,
    "BiWeekly": 26,
    "Monthly": 12,
    "Quarterly": 4,
    "SemiAnnually": 2,
    "Yearly": 1
};

export default frequencies;
